.confirmation {

  &__container {
    max-width: 600px;
    padding: 0 10px;
    margin: 30px auto;
  }

  &__heading {
    color: $black;
    margin: 20px 0;
  }

  p {
    margin: 30px 0;
  }
}
