$black: #111111;
$white: #ffffff;
$gray: #22242b;
$light-gray: #5b5d62;
$main: #131826;
$red: #ff005a;

$secondary-font: 'Fraunces';

$normal: 400;
$semi-bold: 600;
$bold: 700;
$extra-bold: 900;
$mobile: 900px;
