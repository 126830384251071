.powtorka {
	padding: 40px auto;

	@include breakpoint($mobile) {
		padding: 30px auto;
	}

	&-header__heading {
		text-align: center;
		margin-top: 30px;
		font-weight: 900;
		font-size: 35px;

		@include breakpoint($mobile) {
			font-size: 28px;
		}
	}

	&-header__timer {
		text-align: center;
		font-weight: 900;
		font-size: 30px;
		border-radius: 50%;
		margin-bottom: 20px;
	}

	&-header__button {
    background-color: $red;
    padding: 15px 30px;
    font-size: 28px;
    color: $white;
    font-weight: 900;
    display: block;
    text-align: center;
    border-radius: 70px;
		line-height: 1;
		margin-top: 15px;

    &:hover {
      background-color: $black;
    }

    @include breakpoint($mobile) {
      padding: 10px 40px;
      font-size: 18px;
			line-height: 1.3;
    }

		span {
			font-size: 18px;
			letter-spacing: 2px;
			font-weight: 600;

			@include breakpoint($mobile) {
				font-size: 14px;
			}
		}
  }

	&-header__button-wrapper {
		display: flex;
		justify-content: center;
	}

	&-bar {
		margin: 50px 0;
		background-color: #f0f900;
		text-align: center;
	}

	&-bar__text {
		padding: 40px 100px 60px;
		font-size: 28px;

		@include breakpoint($mobile) {
			padding: 40px 20px 60px;
			font-size: 22px;
		}
	}

	&-bar__arrow {
		width: 80px;
	}

	&-for {
		display: grid;
		grid-template-columns: 1fr 1fr;
		gap: 200px;
		padding: 40px 0;

		@include breakpoint($mobile) {
			grid-template-columns: 1fr;
			padding: 0;
			gap: 40px;
		}
	}

	&-for__heading {
		font-weight: 900;
		font-size: 30px;

		@include breakpoint($mobile) {
			font-size: 24px;
		}
	}

	.mark {
		font-weight: 900;
		background-image: linear-gradient(to bottom, #f0f900 0%, #f0f900 10%);
		background-repeat: no-repeat;
		background-size: 100% 0.7em;
		background-position: 0 120%;
	}

	&-for__list {
		margin: 40px 0;

		@include breakpoint($mobile) {
			margin: 20px 0;
		}
	}

	&-for__list-item {
		margin: 20px 0;
		font-size: 22px;
		list-style-type: '-   ';

		@include breakpoint($mobile) {
			font-size: 18px;
		}
	}

	&-effect {
		background-color: #fbb5d3;
		margin-top: 250px;
		text-align: center;
		padding-bottom: 50px;
	}

	&-effect__wrapper {
		max-width: 50%;
		margin: 0 auto;

		@include breakpoint($mobile) {
			max-width: 90%;
		}
	}

	&-effect__heading {
		text-align: left;
	}

	&-effect__img {
		margin-top: -250px;
		max-width: 600px;

		@include breakpoint($mobile) {
			max-width: 100%;
		}
	}

	&-effect__heading {
		margin-top: 20px;
		font-weight: 900;
		font-size: 35px;

		@include breakpoint($mobile) {
			font-size: 24px;
		}
	}

	&-effect__list {
		text-align: left;
		margin: 30px auto;

		@include breakpoint($mobile) {
			max-width: 100%;
		}
	}

	&-effect__list-item--bold {
		font-size: 25px;
		margin-top: 20px;

		@include breakpoint($mobile) {
			font-size: 22px;
		}
	}

	&-effect__list-item {
		margin-top: 10px;
		font-size: 22px;

		@include breakpoint($mobile) {
			font-size: 20px;
		}
	}

	&-counter {
		text-align: center;
		padding: 80px 0 40px;
		border-bottom: 2px solid black;

		@include breakpoint($mobile) {
			padding: 40px 0;
		}
	}

	&-counter__heading {
		font-weight: 900;
		font-size: 38px;

		@include breakpoint($mobile) {
			font-size: 24px;
		}
	}

	&-counter__wrapper {
    display: flex;
    justify-content: center;
    padding: 30px 0;
    gap: 25px;

		@include breakpoint($mobile) {
			gap: 10px;
		}
  }

  &-counter__item {
    display: grid;
    border-radius: 50%;
	background-color: #f7f8e0;
    height: 160px;
    width: 160px;
    justify-content: center;
    align-items: center;
    text-align: center;
    align-content: center;
    font-size: 50px;
    font-weight: $bold;

    span {
      font-size: 16px;

      @media (max-width: $mobile) {
        font-size: 14px;
      }
    }

    @media (max-width: $mobile) {
      width: 70px;
      height: 70px;
      font-size: 26px;
    }
  }

	&-bonus {
		max-width: 50%;
		padding: 40px 0;
		text-align: center;
		border-bottom: 2px solid black;

		@include breakpoint($mobile) {
			max-width: 90%;
		}
	}

	&-bonus__heading {
		font-size: 40px;
		margin-bottom: 20px;

		@include breakpoint($mobile) {
			font-size: 32px;
		}
	}

	&-bonus__text {
		font-size: 25px;
		font-weight: 600;
		margin: 10px 0;

		@include breakpoint($mobile) {
			font-size: 20px;
		}
	}

	&-testimonials {
		border-top: 2px solid black;
		border-bottom: 2px solid black;
		padding: 30px 0;
		text-align: center;
	}

	&-testimonials__heading {
		font-size: 28px;
	}

	&-testimonials__gallery {
		display: grid;
		grid-template-columns: repeat(4, 1fr);
		margin-top: 30px;

		@include breakpoint($mobile) {
			grid-template-columns: 1fr;
			gap: 30px;
		}
	}

	&-course {
		padding: 50px 0;
		text-align: center;
		border-bottom: 2px solid black;
	}

	&-course__heading {
		font-weight: 900;
		font-size: 30px;
		margin-bottom: 40px;

		@include breakpoint($mobile) {
			font-size: 24px;
		}

		span {
			color: $red;
			font-weight: 900;
			font-size: 50px;
		}
	}

	&-course__wrapper {
		max-width: 60%;
		margin: 30px auto;

		@include breakpoint($mobile) {
			max-width: 100%;
		}
	}

	&-course__item {
		font-size: 22px;
		padding: 25px 0;
		font-weight: 600;
	}

	&-course__unlimited {
		color: white;
		font-size: 40px;
		margin: 30px auto;

		span {
			background-color: black;
		}

		@include breakpoint($mobile) {
			font-size: 30px;
		}
	}

	&-author {
		background-color: #f0f900;
		padding: 50px 0;
		margin: 30px 0 0;
		text-align: center;
	}

	&-author__heading {
		font-weight: 900;
		font-size: 30px;

		@include breakpoint($mobile) {
			font-size: 24px;
		}
	}

	&-author__text {
		max-width: 60%;
		font-weight: 600;
		line-height: 1.5;
		margin: 20px auto;

		@include breakpoint($mobile) {
			max-width: 100%;
		}
	}

	&-author__photo {
		width: 350px;

		@include breakpoint($mobile) {
			width: 300px;
		}
	}

	&-author__name {
		margin-top: 30px;
		font-weight: 900;
		font-size: 24px;
	}

	&-author__company {
		letter-spacing: 3px;
		font-size: 22px;
	}
}