.error-page {
  text-align: center;
  margin: 100px auto;

  .button-main {
    background-color: $main;
    color: $white;
    padding: 20px 30px;
    font-size: 18px;
    text-transform: uppercase;
    font-weight: $bold;
    display: inline-block;
    margin-top: 30px;
  }
}
