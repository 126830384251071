body, html {
  font-family: 'Red Hat Display', sans-serif;
  font-size: 18px;
  line-height: 1.2;
  scroll-behavior: smooth;
  color: $black;
  background-color: $white;
  position: relative;
}

.container {
  max-width: 1200px;
  margin-right: auto;
  margin-left: auto;

  @include breakpoint($desktop) {
    max-width: calc(100vw - 60px);
  }

  @include breakpoint($mobile) {
    max-width: calc(100vw - 20px);
  }
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
  padding: 0;
}

b, strong {
  font-weight: $bold;
}

a {
  text-decoration: none;
  color: $black;

  &:hover {
    text-decoration: none;
  }
}

img {
  max-width: 100%;
  height: auto;
}
