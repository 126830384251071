.header {
  display: grid;
  grid-template-columns: repeat(2, 1fr);

  @include breakpoint($desktop) {
    max-width: 100vw;
  }

  @include breakpoint($mobile) {
    grid-template-columns: 1fr;
  }

  &__left {
    padding: 30px 0 30px 30px;
    margin-right: -50px;

    @include breakpoint($mobile) {
      margin: -100px 0 0 0;
      padding: 10px;
      order: 1;
      max-width: 550px;
      justify-self: center;
    }
  }

  &__left-container {
    padding: 30px 50px 0 50px;
    background-color: $main;
    color: $white;
    position: relative;
    z-index: 1;

    @include breakpoint($mobile) {
      padding: 20px 30px;
    }
  }

  &__heading {
    font-size: 24px;
    font-weight: $normal;
    text-align: center;
    letter-spacing: 2px;

    @include breakpoint($mobile) {
      font-size: 20px;
    }
  }

  &__name {
    font-size: 35px;
    text-align: center;
    font-weight: $semi-bold;
    font-style: italic;
    margin: 20px 0;
    font-family: $secondary-font;

    @media (max-width: $mobile) {
      font-size: 30px;
    }
  }

  #mlb2-1894896.ml-form-embedContainer .ml-form-embedWrapper .ml-form-embedBody, #mlb2-1894896.ml-form-embedContainer .ml-form-embedWrapper .ml-form-successBody {
    padding: 10px 0 !important;
  }

  &__when {
    background-color: $black;
    padding: 15px;
    text-align: center;
    position: relative;
    z-index: 1;
    color: $white;
  }

  &__when-heading {
    letter-spacing: 3px;
  }

  &__when-day {
    font-weight: $bold;
    font-size: 20px;
    margin: 3px 0;
  }

  &__when-hour {
    font-size: 20px;
  }

  &__photo {
    height: 600px;
    object-position: top;
    aspect-ratio: 1 / 1;
    object-fit: cover;

    @include breakpoint($mobile) {
      margin: 0 auto;
      display: block;
      height: 400px;
      width: 100%;
    }
  }

  &__arrow {
    font-size: 50px;
    text-align: center;
    line-height: 0;
    margin: 20px 0;
    display: flex;
    justify-content: center;
  }
}

.about {
  &__text {
    font-size: 24px;
    text-align: center;
    padding: 30px 0;
    line-height: 1.5;

    @include breakpoint($mobile) {
      font-size: 20px;
    }
  }

  &__author {
    text-align: center;
    font-family: $secondary-font;
    font-size: 20px;
    margin-top: 20px;
  }

  &__counter {
    display: flex;
    justify-content: center;
    padding: 30px 0;
    gap: 5px;
  }

  &__counter-item {
    display: grid;
    border: 1px solid $black;
    border-radius: 50%;
    height: 80px;
    width: 80px;
    justify-content: center;
    align-items: center;
    text-align: center;
    align-content: center;
    font-size: 28px;
    font-weight: $normal;

    span {
      font-size: 16px;

      @media (max-width: $mobile) {
        font-size: 14px;
      }
    }

    @media (max-width: $mobile) {
      width: 70px;
      height: 70px;
      font-size: 26px;
    }
  }
}

.webinar {
  &__container {
    margin-top: 20px;
    background-color: $main;
    padding: 50px;
    border-radius: 10px;
    display: grid;
    grid-template-columns: 2fr 1fr;
    align-items: center;
    gap: 30px;

    @include breakpoint($mobile) {
      grid-template-columns: 1fr;
      padding: 30px 15px;
      margin: 10px;
    }
  }

  &__left {
    border-right: 2px solid $white;
    padding-right: 40px;

    @include breakpoint($mobile) {
      border: 0;
      padding: 0;
    }
  }

  &__title {
    font-size: 36px;
    color: $white;

    @include breakpoint($mobile) {
      font-size: 28px;
      text-align: center;
    }
  }

  &__list {
    margin: 30px 0;
    color: $white;
  }

  &__list-item {
    display: flex;
    gap: 10px;
    font-size: 22px;
    margin: 15px 0;

    @include breakpoint($mobile) {
      font-size: 20px;
    }
  }

  &__list-check {
    color: $red;
  }

  &__button {
    background-color: $red;
    padding: 15px 30px;
    font-size: 30px;
    color: $white;
    font-weight: $bold;
    display: block;
    text-align: center;
    border-radius: 70px;

    &:hover {
      background-color: $black;
    }

    @include breakpoint($mobile) {
      padding: 10px 40px;
      font-size: 24px;
    }
  }
}

.footer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 20px 0;

  @include breakpoint($mobile) {
    grid-template-columns: 1fr;
  }

  &__nav {
    display: flex;
    align-items: center;
    gap: 20px;
    padding: 20px 0;

    @include breakpoint($mobile) {
      justify-content: center;
    }
  }

  &__link {
    border-left: 2px solid $black;
    padding: 10px 0 10px 15px;
    font-size: 20px;
    font-weight: $bold;
  }

  &__social {
    display: flex;
    gap: 5px;
    justify-content: end;
    align-items: center;
    text-transform: uppercase;
    font-weight: $bold;

    @include breakpoint($mobile) {
      margin-top: 10px;
      justify-content: center;
    }
  }

  &__social-text {
    @include breakpoint($mobile) {
      display: none;
    }
  }
}
